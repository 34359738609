#navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#navbar > div {
    margin-left: 1.5%;
    margin-right: 1.5%;
}

#navbar-wide > div {
  margin-left: 1.5%;
  margin-right: 1.5%;
}

#showCanvas{
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0.9rem;
  left: 0.2rem;
  margin: 0.5rem;
  background-color: rgb(81, 84, 93);
  align-content: center;
}

/* Kept for review
@media screen and (max-width: 600px) {
  #navbar {
    position: relative;
    z-index: 3;
    top: 89%;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .tab {
    border-left: none !important;
  }

  #dashboard {
    margin-bottom: 12rem !important;
  }

  #display {
    margin: 0;
  }
}
*/

.helpButton {
  text-align: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: white;
  border: 2px solid black;
  font-size: 1.75rem;
  font-weight: bold;
  scale: 1;
  transition: scale, .2s;
  align-content: center;
}

.helpButton:hover {
  scale: 1.05;
  transition: scale, .2s;
  cursor: pointer;
}
.helpDiv{
  cursor: pointer;
  width: 80%;
}
.drawerNav {
  height: 100%;
  background-color: rgb(81, 84, 93); 
}

.selected {
  border-left: 3px solid gray; 
}

@media screen and (max-width: 800px) {
  .offcanvas {
    --bs-offcanvas-width: 100% !important;
    --bs-offcanvas-padding-y:
  }
  .offcanvas-header{
    padding: 0px 20px !important;

  }
  .tab {
    display: flex;
    align-items: center;
    width: 10rem;
    height: 5rem;
    font-size: 1.2em;
    font-weight: bold;
    padding-left: 1rem !important;
    color: white;
  }
}



.offcanvas {
  --bs-offcanvas-width: 22%;
  margin-right: auto;
}

.offcanvas-header{
  padding: 0px;
  min-height: 80px !important;
}

.offcanvas-title.h5{ /*Header Text*/
 overflow:hidden;
 padding: 4px 0px;
 align-items: center;
 line-height: 20px;
} 

.offcanvas-body{
  padding: 0px;
}

.accordianWrapper{
  background-color: rgb(81, 84, 93); 
  max-width: 98%;
}
.microbit.active{
  background-color: rgb(69, 71, 79); 
  border-radius: 10px;
}
.acItem{
  color: white;
}

/* Overrides bootstrap styles for displaying accordions for the navbar */

.styledAccordion > * {
  background: transparent;
  padding-left: .5vw;
  padding-right: .5vw;
  border: none;
}

.buttonList > li > button {
  background: transparent;
  border: none;
  color: white;
  font-weight: bold;
}

.buttonList > li {
  margin-top: 1vh;
}

.accordion-button {
  background: rgb(81, 84, 93) !important;
  border: none !important;
  color: white !important;
  outline: none !important;
  font-size: 1.25rem;
  font-weight: bold;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
