#legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    height: fit-content;
    padding: .25rem;
    background-color: rgba(255, 255, 255, .5);
    box-shadow: 0px 0 2px 1px darkgrey;
}