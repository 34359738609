* {
  box-sizing: border-box;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100vh;
  overflow: hidden;
  margin: 0; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
@media screen and (max-width: 800px) {
  #display {
    width: 100% !important;
    margin: 0% !important;
    border-radius: 0 !important;
  }

  #plot-bar {
    border-radius: 0 !important;
  }

  #microbit-name{
    padding-left: 6% !important; /*Shifts the microbit name to allow hamburger menu to have space*/
  }
}
@media screen and (min-width: 800px) {
  #dashboard {
    width: 78vw !important; 
    margin-left: auto;
  }

}

.connectButton {
  border-radius: 10px;
  color: black;
  border: 3px solid #002967;
  background-color: #59a1e5;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.30);
}

.connectButton:hover {
  background-color: #4991D4;
  color: black;
  border: 3px solid #002967;
  transition: background-color .2s, scale .2s;
  scale: 1.025;
  cursor: pointer;
}

.tab {
  display: flex;
  align-items: center;
  width: 10rem;
  height: 5rem;
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 5%;
  color: white;
}

.tab:first-child {
  border-left: none;
}

.tab:hover {
  cursor: pointer;
}

button:hover {
  scale: 1.05;
  transition: scale, .2s;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px; 
  margin: 1% 1vw 0 1vw;
  height: 40px;
  font-size: 20px;
  border: 0;
  background: transparent;
}

.actionButton:hover {
  cursor: pointer;
  scale: 1.1;
  transition: scale, .2s;
}

.actionButton > * {
  width: 40px;
  height: 40px;
}

.disabled {
  color: lightgrey;
}

#display {
  width: 97%;
  height: 100%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 1.5%;
  overflow-y: hidden;
}

tr:hover {
  filter: brightness(95%);
}

.loading-bar {
  width: 100%;
  height: 20px;
  background-color: blue;
  border-radius: 10px;
}

.progress-bar {
  height: 100%;
  background-color: green;
  border-radius: 10px;
}

.container {
  width: 100%;
  
}

#plot-bar{
  display: flex;
  padding: 0 1rem;
  width: 100%;
  background-color: #C6C7C9;
  height: 5rem;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid black;
}



.memory-bar-label{
  color: #e9e9e9;
  font-size: medium;
  font-size-adjust: inherit;
}

  h7 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0.5em;
    margin-right: 0;
    font-weight:600;  
  }

  .iconButton {
    padding: 1;
    filter: brightness(100%);
    transition: filter .2s;
  }
  
  .iconButton:hover {
    cursor: pointer;
    filter: brightness(60%);
    transition: filter .2s, background-color .2s;
  }

  .memoryBar {
    display: flex;
    align-items: center;
    width: 75%;
    height: 40%;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid black;

    font-size: 1.25rem;
  }